
export const transctiondata=[
    {date:"22 th, May 2021",balance:"1,152,837",isIncrease:false},
    {date:"22 th, May 2021",balance:"1,152,837",isIncrease:true},
    {date:"22 th, May 2021",balance:"1,152,837",isIncrease:true},
    {date:"22 th, May 2021",balance:"1,152,837",isIncrease:false},
]
export const coinChartData =[
    { name: "Aug 2018", value: 25187.44 },
  { name: "Sep 2018", value: 27356.99 },
  { name: "Oct 2018", value: 34698.98 },
  { name: "Nov 2018", value: 37587.55 },
  { name: "Dec 2018", value: 27577.4 },
  { name: "Jan 2019", value: 25577.4 },
  { name: "Feb 2019", value: 18577.4 },
  { name: "Mar 2019", value: 28577.4 },
  { name: "Apr 2019", value: 41577.4 },
    { name: "May 2019", value: 41577.4 },
    
  ]