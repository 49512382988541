import images from "../../../constants/images"

export const coinData = [
    { title:"Lumen",rating:5.15,isIncrease:true,unit:"$",chartdata:[
        { name: 1, value: 10 },
		{ name: 2, value: 15 },
		{ name: 3, value: 17 },
		{ name: 4, value: 18 },
		{ name: 5, value: 18 },
		{ name: 6, value: 20 },
		{ name: 7, value: 25 },
		{ name: 8, value: 28 },
		{ name: 9, value: 33 },
        { name: 10, value: 32 },
        { name: 12, value: 31 },
        { name: 13, value: 30 },
        { name: 14, value: 33 },
        { name: 15, value: 34 },
        { name: 16, value: 34 },
        { name: 17, value: 35 },
        { name: 18, dotval:35, value: 35 },
    ]},
    { title:"USD Coin",rating:1.15,isIncrease:false,unit:"XLM",chartdata:[
        { name: 1, value: 35 },
		{ name: 2, value: 32 },
		{ name: 3, value: 31 },
		{ name: 4, value: 30 },
		{ name: 5, value: 28 },
		{ name: 6, value: 27 },
		{ name: 7, value: 24 },
		{ name: 8, value: 20 },
		{ name: 9, value: 18 },
        { name: 10, value: 15 },
        { name: 12, value: 14 },
        { name: 13, value: 15 },
        { name: 14, value: 16 },
        { name: 15, value: 14 },
        { name: 16, value: 10 },
        { name: 17, value: 9 },
        { name: 18, dotval:9, value: 9 },
    ]}
]
export const CoinTradeChartData = [
    { name: "Aug", value: 0.2518744 },
    { name: "Sep", value: 0.2735699 },
    { name: "Oct", value: 0.3469898 },
    { name: "Nov", value: 0.3758755 },
    { name: "Dec", value: 0.275774 },
    { name: "Jan", value: 0.255774 },
    { name: "Feb", value: 0.185774 },
    { name: "Mar", value: 0.285774 },
    { name: "Apr", value: 0.415774 },
    { name: "May", value: 0.415774 }
]
export const maketRating ={ isIncreasing:false, chartData:[
    { name: 1, value: 35 },
    { name: 2, value: 32 },
    { name: 3, value: 31 },
    { name: 4, value: 30 },
    { name: 5, value: 28 },
    { name: 6, value: 27 },
    { name: 7, value: 24 },
    { name: 8, value: 20 },
    { name: 9, value: 18 },
    { name: 10, value: 15 },
    { name: 12, value: 14 },
    { name: 13, value: 15 },
    { name: 14, value: 16 },
    { name: 15, value: 14 },
    { name: 16, value: 10 },
    { name: 17, value: 9 },
    { name: 18, dotval:9, value: 9 },
]}
export const PurchaseData = [
    {price:0.115847,Amount:119.9283712,Total:13.81115847},
    {price:0.115847,Amount:119.9283712,Total:13.81115847},
    {price:0.115847,Amount:119.9283712,Total:13.81115847},
    {price:0.115847,Amount:119.9283712,Total:13.81115847},
    {price:0.115847,Amount:119.9283712,Total:13.81115847},
    {price:0.115847,Amount:119.9283712,Total:13.81115847},
    {price:0.115847,Amount:119.9283712,Total:13.81115847},
    {price:0.115847,Amount:119.9283712,Total:13.81115847},
    {price:0.115847,Amount:119.9283712,Total:13.81115847},
    {price:0.115847,Amount:119.9283712,Total:13.81115847},
    {price:0.115847,Amount:119.9283712,Total:13.81115847},
    {price:0.115847,Amount:119.9283712,Total:13.81115847},
    
]
export const  ordersData = [
    {isSell:false,amount:119.9283712,price:0.115847,total:13.8115847},
    {isSell:true,amount:119.9283712,price:0.115847,total:13.8115847},
    {isSell:false,amount:119.9283712,price:0.115847,total:13.8115847},
    {isSell:true,amount:119.9283712,price:0.115847,total:13.8115847},
    {isSell:false,amount:119.9283712,price:0.115847,total:13.8115847},
    {isSell:true,amount:119.9283712,price:0.115847,total:13.8115847},
    {isSell:false,amount:119.9283712,price:0.115847,total:13.8115847},
    {isSell:true,amount:119.9283712,price:0.115847,total:13.8115847},
    {isSell:false,amount:119.9283712,price:0.115847,total:13.8115847},
    {isSell:true,amount:119.9283712,price:0.115847,total:13.8115847},
]

export const ExtraTradeData=[
    {symbol:"BTC",curName:"Bitcoin",price:"21,843",change:"0.11",volume:"206.83",marketCap:"400.83",Balance:"8,384",coinImg:images.BitCoin},
    {symbol:"ETC",curName:"Ethereum",price:"21,843",change:"0.11",volume:"206.83",marketCap:"400.83",Balance:"8,384",coinImg:images.Ethereum},
    {symbol:"LTC",curName:"LiteCoin",price:"21,843",change:"0.11",volume:"206.83",marketCap:"400.83",Balance:"8,384",coinImg:images.LiteCoin},
    {symbol:"BTC",curName:"Bitcoin",price:"21,843",change:"0.11",volume:"206.83",marketCap:"400.83",coinImg:images.BitCoin},
    {symbol:"ETC",curName:"Ethereum",price:"21,843",change:"0.11",volume:"206.83",marketCap:"400.83",coinImg:images.Ethereum},
    {symbol:"LTC",curName:"LiteCoin",price:"21,843",change:"0.11",volume:"206.83",marketCap:"400.83",coinImg:images.LiteCoin},
]