import images from "../../../../constants/images"
export const NFTListItems = [
    {title:"The Golden Ratio" , coinType:"Ethereum",coinImg:images.nftCoinImg,img:images.nft1,price:10000},
    {title:"The Golden Ratio" , coinType:"Ethereum",coinImg:images.nftCoinImg,img:images.nft2,price:40000},
    {title:"The Golden Ratio" , coinType:"Ethereum",coinImg:images.nftCoinImg,img:images.nft3,price:10000},
    {title:"The Golden Ratio" , coinType:"Ethereum",coinImg:images.nftCoinImg,img:images.nft4,price:30000},
    {title:"The Golden Ratio" , coinType:"Ethereum",coinImg:images.nftCoinImg,img:images.nft1,price:10000},
    {title:"The Golden Ratio" , coinType:"Ethereum",coinImg:images.nftCoinImg,img:images.nft2,price:40000},
    {title:"The Golden Ratio" , coinType:"Ethereum",coinImg:images.nftCoinImg,img:images.nft3,price:10000},
    {title:"The Golden Ratio" , coinType:"Ethereum",coinImg:images.nftCoinImg,img:images.nft3,price:10000},
    {title:"The Golden Ratio" , coinType:"Ethereum",coinImg:images.nftCoinImg,img:images.nft3,price:10000},
    {title:"The Golden Ratio" , coinType:"Ethereum",coinImg:images.nftCoinImg,img:images.nft4,price:30000},

]
export const BidList = [
    {Img:images.detailManImg,name:"KHALID SAIED",time:"29 MARCH 13:08:57",price:"50,000 USD",coinImg:images.nftCoinImg,coinType:"Ethereum"},
    {Img:images.detailManImg,name:"KHALID SAIED",time:"29 MARCH 13:08:57",price:"50,000 USD",coinImg:images.nftCoinImg,coinType:"Ethereum"},
    {Img:images.detailManImg,name:"KHALID SAIED",time:"29 MARCH 13:08:57",price:"50,000 USD",coinImg:images.nftCoinImg,coinType:"Ethereum"}
]