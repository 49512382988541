import tableAvatar from "../../../assets/png/tableAvatar.png"
import images from "../../../constants/images"
export const tableData =[
    {browser:"Google Chrome",ipAddress:"Cluj - Napoca, RO 82 . 7968 . 20",date:"19th Aug 2021",img:images.Chrome,status:"Success"},
    {browser:"Google Chrome",ipAddress:"Cluj - Napoca, RO 82 . 7968 . 20",date:"19th Aug 2021",img:images.Chrome,status:"Success"},
    {browser:"Google Chrome",ipAddress:"Cluj - Napoca, RO 82 . 7968 . 20",date:"19th Aug 2021",img:images.Chrome,status:"Success"},
    {browser:"Google Chrome",ipAddress:"Cluj - Napoca, RO 82 . 7968 . 20",date:"19th Aug 2021",img:images.Chrome,status:"Faild"},
    {browser:"Google Chrome",ipAddress:"Cluj - Napoca, RO 82 . 7968 . 20",date:"19th Aug 2021",img:images.Chrome,status:"Faild"},
    {browser:"Google Chrome",ipAddress:"Cluj - Napoca, RO 82 . 7968 . 20",date:"19th Aug 2021",img:images.Chrome,status:"Faild"},
    {browser:"Google Chrome",ipAddress:"Cluj - Napoca, RO 82 . 7968 . 20",date:"19th Aug 2021",img:images.Chrome,status:"Faild"},
    {browser:"Google Chrome",ipAddress:"Cluj - Napoca, RO 82 . 7968 . 20",date:"19th Aug 2021",img:images.Chrome,status:"Success"},
    {browser:"Google Chrome",ipAddress:"Cluj - Napoca, RO 82 . 7968 . 20",date:"19th Aug 2021",img:images.Chrome,status:"Success"},
    {browser:"Google Chrome",ipAddress:"Cluj - Napoca, RO 82 . 7968 . 20",date:"19th Aug 2021",img:images.Chrome,status:"Success"},
    {browser:"Google Chrome",ipAddress:"Cluj - Napoca, RO 82 . 7968 . 20",date:"19th Aug 2021",img:images.Chrome,status:"Success"},
    {browser:"Google Chrome",ipAddress:"Cluj - Napoca, RO 82 . 7968 . 20",date:"19th Aug 2021",img:images.Chrome,status:"Success"},
]