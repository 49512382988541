import React from "react";

// export const themes = {
//     light: {
//         foreground: 'black',
//         background: 'white',
//     },
//     dark: {
//         foreground: 'white',
//         background: 'black',
//     },
// };

// export const isToggle = true;

export const ThemeContext = React.createContext();
export const AuthContext = React.createContext();