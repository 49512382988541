import tableAvatar from "../../../assets/png/tableAvatar.png"
export const tableData =[
    {name:"Rania Ahmed",walletAddress:"GDNAJRWQKRYHHFASYDAHDHBSANCHSADGFHSDHAS",img:tableAvatar},
    {name:"Rania Ahmed",walletAddress:"GDNAJRWQKRYHHFASYDAHDHBSANCHSADGFHSDHAS",img:tableAvatar},
    {name:"Rania Ahmed",walletAddress:"GDNAJRWQKRYHHFASYDAHDHBSANCHSADGFHSDHAS",img:tableAvatar},
    {name:"Rania Ahmed",walletAddress:"GDNAJRWQKRYHHFASYDAHDHBSANCHSADGFHSDHAS",img:tableAvatar},
    {name:"Rania Ahmed",walletAddress:"GDNAJRWQKRYHHFASYDAHDHBSANCHSADGFHSDHAS",img:tableAvatar},
    {name:"Rania Ahmed",walletAddress:"GDNAJRWQKRYHHFASYDAHDHBSANCHSADGFHSDHAS",img:tableAvatar},
    {name:"Rania Ahmed",walletAddress:"GDNAJRWQKRYHHFASYDAHDHBSANCHSADGFHSDHAS",img:tableAvatar},
    {name:"Rania Ahmed",walletAddress:"GDNAJRWQKRYHHFASYDAHDHBSANCHSADGFHSDHAS",img:tableAvatar},
    {name:"Rania Ahmed",walletAddress:"GDNAJRWQKRYHHFASYDAHDHBSANCHSADGFHSDHAS",img:tableAvatar},
    {name:"Rania Ahmed",walletAddress:"GDNAJRWQKRYHHFASYDAHDHBSANCHSADGFHSDHAS",img:tableAvatar},
    {name:"Rania Ahmed",walletAddress:"GDNAJRWQKRYHHFASYDAHDHBSANCHSADGFHSDHAS",img:tableAvatar},
    {name:"Rania Ahmed",walletAddress:"GDNAJRWQKRYHHFASYDAHDHBSANCHSADGFHSDHAS",img:tableAvatar},
]